import React from 'react';
import './styles.scss';

const Footer = () => {
    return (
        <div className='Footer'>
            <h1>Footer</h1>
        </div>
    )
}

export default Footer;