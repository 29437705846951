import React from 'react';
import './styles.scss';

const ContactPage = props => {
    return (
        <div className="ContactPage">
            <h1>ContactPage !</h1>
        </div>
    );
};

export default ContactPage;